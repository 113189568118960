import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import NavButtons from 'components/Software/Windows/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Windows Software for your INSTAR IP Camera",
  "path": "/Software/Windows/WebCam_XP/",
  "dateChanged": "2017-12-05",
  "author": "Mike Polinowski",
  "excerpt": "The Windows app WebCam XP for your PC or Laptop. Control your camera from your computer and harness the computing power for advanced features.",
  "image": "./P_SearchThumb_WebcamXP.png",
  "social": "/images/Search/P_SearchThumb_WebcamXP.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Windows-WebCam-XP_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Webcam XP' dateChanged='2017-12-05' author='Mike Polinowski' tag='INSTAR IP Camera' description='The Windows app WebCam XP for your PC or Laptop. Control your camera from your computer and harness the computing power for advanced features.' image='/images/Search/P_SearchThumb_WebcamXP.png' twitter='/images/Search/P_SearchThumb_WebcamXP.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/Windows/WebCam_XP/' locationFR='/fr/Software/Windows/WebCam_XP/' crumbLabel="Webcam XP" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h1>
    <h2 {...{
      "id": "windows",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#windows",
        "aria-label": "windows permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Windows`}</h2>
    <h3 {...{
      "id": "webcam-xp",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#webcam-xp",
        "aria-label": "webcam xp permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Webcam XP`}</h3>
    <p>{`WebcamXP allows you to monitor your belongings from any location with access to Internet by turning your computer into a security system. Connect remotely by using other computers or your mobile phone, broadcast live video to your website, Schedule automatic captures or recordings or Trigger specific actions using the motion detector - and everything tailored for low hardware requirements.`}</p>
    <p>{`In order to integrate your INSTAR IP camera in `}<a href="http://www.webcamxp.com/" target="_blank" rel="noopener noreferrer">{`Webcam XP`}</a>{` we recommend you to download the latest version from `}<a href="http://www.webcamxp.com/download.aspx" target="_target">{`here`}</a>{`.`}</p>
    <h3 {...{
      "id": "add-a-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#add-a-camera",
        "aria-label": "add a camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add a Camera`}</h3>
    <p>{`After you installed the newest version of WebcamXP please open the program, right-click the `}<strong parentName="p">{`+ symbol`}</strong>{` on one of the available channels to add a new network camera.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "794px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2b092b0070032bb9d381d1c1973bdb79/7de01/WebcamXP_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "79.56521739130434%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAAsTAAALEwEAmpwYAAAD1UlEQVQozz2PbUwbZQCAz5jpoj+dJkpxsQtuyEI0/liyJWoQ2OgKDEoZyhCVwcxGtrGh64JI+RhfZSxioG1Y7UZb2lIo/bjre317V0o921LN2IIYXZfI1kKhlGt7QFsYMIMak+fXkzw/HqRRJGlolzT1yNq+V3T0q7slI92SEZFUJ5LquiUjXQPargFNl1i768W7vkeqE4nVon6VsKEVIYlJaCUAsEJIQEgSxASEpNmEjeuNAECPx+t2TzmdP9kJwk4SKGoxmzEUAxMTTrVOj4jVMplO29kpKi0pKePzS0t4RYWFBVzuSQ6XV1RUXnaaz+O13Wifmn7k9Pw+OzM782DG99D35x++cROGfCUauHLrxxxuUWp6Bu9qY/75K3mVZzlfXOBUVGRX1BTXNbPfP/JxVvb0b/Pe6blIOMrQTIyOBfwLAE4g9eK7HSoD92TBseLPevBf20dJkWa8VSNr7L1R2SgS6j3cmq9zs7J2tjaT8bXwcji8HGZizPbTrXv3HiCCvgGxUl5WXJz23uHP6ysvNXFabld13Ok99+2lvFOH88/kZB49kpudG4syj+cW5v3BJ3/5A3MLybVNj/sXRDYoUyqUhQW8A+lIVV2qUPRutTDzcof0y+s1FwWsj3JYrDf3Hs/Ni9CRQHDpkS/ge/hkYYFOxJ/dvz+LWABwuSh+SdkbbOTU2RcvCg7WXkvhVmecF+y/Jkzl8A4d/eD14zmcjY1kOLg4PxcIBUNxJr6zsU1RbsRgNNsdkyU8/j4Wkv9pOqf0YNFpVnlV2rnat3nlGWeqj32Yuz/vBIcO0zE6FqOZOBNf/yd2u72I/u5NXNXF53+CPI+ksF868M7LLPaeVPaet9L27kt54VDmq6+89lzeCU4inlhj1ugQvRpZTawmtje2XW4vopB0DvU1SqWyVmGr8Lvmb+oF1wUNzU1tLcK2FmHr5TrBhdqrUslgdCW6ElpZWVoJLy5Hw9GdjW2XawoZVioUcrl+TD+qHSWsBAlJEhIO0kE5HONGa/MtbUuvSq2Da5GY/7F/cX4xGAiGgstbiacU5UFkfV2Sm22aYa38ttxsQK0WHGAAYAC3gLFR0w9STW//kNEEQ4tLkaUwQzPr0fXkavLZ5s7us3p4WKVUomb0vwbg/wIADgGuVI3J5Fq7zTblmXJRLqdjknJSlJPyuj1a3RjipKifXS673U7a7QRJ/g+ENswCbARBkjYbSQIcRzGAWXDMgqMYDiExpNIgEMfNBgNqNEEArABYLZZdAMAxzIKiNhyHOE5YccyEjWqNOo1hbMSkGhpR3NEODg79DSOqV5e+/TEuAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2b092b0070032bb9d381d1c1973bdb79/e4706/WebcamXP_02.avif 230w", "/en/static/2b092b0070032bb9d381d1c1973bdb79/d1af7/WebcamXP_02.avif 460w", "/en/static/2b092b0070032bb9d381d1c1973bdb79/31644/WebcamXP_02.avif 794w"],
              "sizes": "(max-width: 794px) 100vw, 794px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2b092b0070032bb9d381d1c1973bdb79/a0b58/WebcamXP_02.webp 230w", "/en/static/2b092b0070032bb9d381d1c1973bdb79/bc10c/WebcamXP_02.webp 460w", "/en/static/2b092b0070032bb9d381d1c1973bdb79/ae710/WebcamXP_02.webp 794w"],
              "sizes": "(max-width: 794px) 100vw, 794px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2b092b0070032bb9d381d1c1973bdb79/81c8e/WebcamXP_02.png 230w", "/en/static/2b092b0070032bb9d381d1c1973bdb79/08a84/WebcamXP_02.png 460w", "/en/static/2b092b0070032bb9d381d1c1973bdb79/7de01/WebcamXP_02.png 794w"],
              "sizes": "(max-width: 794px) 100vw, 794px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2b092b0070032bb9d381d1c1973bdb79/7de01/WebcamXP_02.png",
              "alt": "Windows Webcam XP",
              "title": "Windows Webcam XP",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Right-click the video channel you want to assign and choose Network Cameras' from the context menu.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "797px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5ab954c980bd07b8fc9c8600c38937da/43fbc/WebcamXP_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "79.13043478260869%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAAsTAAALEwEAmpwYAAADZElEQVQoz1WSWW8bVRiG53dwh7gq+QFIiLWULYUqm9sIbhEqRRABUiQUqIQAiTQRtESJHS9xvMf7nDlnzswcZ9w0lRw3peWim+OiqMXreLzGM+MlYxvZkSLx6NF38Urf99581OKq7brJ+afZa7QHTY6Q2RU1u6IWN231AIubXndG1p3hdWfkJLeOctNmeM3mv2FyUxwf396OY04gRCREjMXigiBCyEUiDMuSRPLu3t7+7u0EIbEYIRBhwCCIsCBss1igVtxWi997bfmPmamp6anJyYkL4x9+8N67586dPXt+fNwwPXXho/NfXvnCvOFbXfd4XB6X0+Xf8ns9PrNlg7q8bPxq2fKx4dMXXnzp8vzPn80tfP7tj18vLH5zdWnuh8W5hcUr879cXTLbfNsrRg8dAQhACKDP57dtOKnvjJu/usOT07PvTxjSjx9WK5XnmWy+WMoVSrmClC1I/2bz9YZSqdVIjGCWRQhxPE8EATGImr+xuua0f2IwvPHOm4/+vpt5dlgsFPK5XC6bPZnZTKYsy0WpBDEHmSHsCDoKKIvZ6nZ5JiYm33p7rHvckcsVSZJKpVJJkoqSJMuyXJJ1XT9qKpgngKYBDeCI4TKD2GQyMXtp9tXXz3S6rXq90e129f8zGAwUrRUTb2KWxRgLgkAIATRDhaLMzs7ti4aLr7w2VpKlpwdPq5VKrVo9tVIu93RdUTXIcpFwOBqJAhrQND1sDjp+j3l/m5m59PLYmVq9kckXm2q71da19vGJSqs9bFY1IsZPmwVBGDY7Tdcc1783Gi2mNZNcrhcr9brSqh5pp1Yaar/fV1SNJyJiEAtZjsUcyw2X3S6X024P+QM8FrLZfCqVLkpy40ipN5onVmuNXq+naBrHxRBAQxmEIQZRQFlXlo1LP3nd3oA/pKiaqmlNVW11Oie2O91WuzMYDJqKGqWhz+PdtDuCgWAoEAr4A5TH43Y5HQAwN8WdZqPZabX7en+g9we9fr/X17vHeve4rWrVap0XYpBheI7nMEYI0TSg4rd2E3sJURR3bu0m7/yV3L93kD48SB+mUv88ePDk4ePUoyfpO/v3kvv3hVic5wk3khARIkxhFrMMAwEQRgcxQpBhhp8EAB2NshCyEPIYMzQMbtHBLToUAF5XaNPm9fvC/wHBobymddiWMgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5ab954c980bd07b8fc9c8600c38937da/e4706/WebcamXP_03.avif 230w", "/en/static/5ab954c980bd07b8fc9c8600c38937da/d1af7/WebcamXP_03.avif 460w", "/en/static/5ab954c980bd07b8fc9c8600c38937da/48116/WebcamXP_03.avif 797w"],
              "sizes": "(max-width: 797px) 100vw, 797px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5ab954c980bd07b8fc9c8600c38937da/a0b58/WebcamXP_03.webp 230w", "/en/static/5ab954c980bd07b8fc9c8600c38937da/bc10c/WebcamXP_03.webp 460w", "/en/static/5ab954c980bd07b8fc9c8600c38937da/9eee1/WebcamXP_03.webp 797w"],
              "sizes": "(max-width: 797px) 100vw, 797px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5ab954c980bd07b8fc9c8600c38937da/81c8e/WebcamXP_03.png 230w", "/en/static/5ab954c980bd07b8fc9c8600c38937da/08a84/WebcamXP_03.png 460w", "/en/static/5ab954c980bd07b8fc9c8600c38937da/43fbc/WebcamXP_03.png 797w"],
              "sizes": "(max-width: 797px) 100vw, 797px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5ab954c980bd07b8fc9c8600c38937da/43fbc/WebcamXP_03.png",
              "alt": "Windows Webcam XP",
              "title": "Windows Webcam XP",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Choose your INSTAR camera model in the Network Camera Wizard.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "796px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e35cba2bbcee837c4e99922aafc7158b/d48f1/WebcamXP_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "79.13043478260869%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAAsTAAALEwEAmpwYAAADeklEQVQozyWS21MbdRTH9y/xzRkfGZ2++lKlTLAQtKC++OR0pj7Yi6POWPpSHWegtswgEEKAwCab2ybZ3d91L0mVSwvY0oo6WkBqAiQbmmwuuylsEkickM98n87M93zPOXOYn1zeBy523MNNeSOuhaibjbvZ+IxP8PjFGZ8wvRibXoy52diMr133+AUPJ055+cnZ0MjPLJNMLicSDylVNe2hqiY17RdFSQBAhDikVF1bf7K2/tvyyiNV1TRVg4gAiCGiCS0ZlwgzHfTORkIjo/eHBq8MDV4Z/OjDyx/0OnouXep+v7+v75OPhwacfV/dujW7EJ7ycJyf87O+SCgS4AKTbi/zxQPXjbHZHufQG2++de3bHz6/MXz15p3rt0du3rl3fXjky9sj176+e3ds3htKjE/5gQCQhCBAwUDI5WGZb9zsj1y89/JA/6efpXf/KRaLBxldP8rnXhX0XF5/ld8/zFiv7bxhEJkQjBFEsiwnNJWPQ+a7CZfLtzDodL7n6N7+89lBOp3Tc9lMJnvYVubw8GB/3ygUMnpOAAhCCADACFOCQ7zEzM/OcX7O4ejtcbxzYtup9H7BMOr1es2u1Wv1Dq1WyyiVAcKSKEmiBCHECIV5wFBK19fXBpwDF7u7qpa1t/cyq+umadVqNdu2c0dHpmW1Wq2yaVFFpaSNoiiaqoSjgBEB+nVpxdnf/+7FrhPbzmazhmHUG43mOcViyTTb5lLZFCQoxgUhLkiihAAIRQEjsGNqcLSvb+DtC13V6rGuZ4tGsa1S2bKqlUqlM3a5YhFFbV+MYFlR1HYyZDj3KDs+PDnhnpuZy2ZzBcNotVrNZvP09HTv373tF9v5fL6dXDERljFECGGCiUJoOAaZAOf3LXj5MC9T5cS2G43G2elZh3QqnfovVSqVOmYIKQYISQgBLCMSikJmfuKe6/73fl8gHhVN09L1nG3bnfB2o3POd67wMSHIBdgFlo9E43yUDUaZUDDo9/klUVpeWimXyuViqVFrnDXOmo2z01rDMqvH1WP79XEhb0BMMIQylQkhFKNwDDArq6uPHj9OJpNLK6sbT58/2fx9Zze1s5t6sfNy64+/1zY2n2/9tflsa+PpFlESsqxRWaWypmlJXpIZlcpIkpAEFEopxrT9gBB3XkmSZEoJJjKlQIR8WORDQiwCOJZfnA+wnPg/V6G9f7W06VgAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e35cba2bbcee837c4e99922aafc7158b/e4706/WebcamXP_04.avif 230w", "/en/static/e35cba2bbcee837c4e99922aafc7158b/d1af7/WebcamXP_04.avif 460w", "/en/static/e35cba2bbcee837c4e99922aafc7158b/9b205/WebcamXP_04.avif 796w"],
              "sizes": "(max-width: 796px) 100vw, 796px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e35cba2bbcee837c4e99922aafc7158b/a0b58/WebcamXP_04.webp 230w", "/en/static/e35cba2bbcee837c4e99922aafc7158b/bc10c/WebcamXP_04.webp 460w", "/en/static/e35cba2bbcee837c4e99922aafc7158b/7fcbc/WebcamXP_04.webp 796w"],
              "sizes": "(max-width: 796px) 100vw, 796px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e35cba2bbcee837c4e99922aafc7158b/81c8e/WebcamXP_04.png 230w", "/en/static/e35cba2bbcee837c4e99922aafc7158b/08a84/WebcamXP_04.png 460w", "/en/static/e35cba2bbcee837c4e99922aafc7158b/d48f1/WebcamXP_04.png 796w"],
              "sizes": "(max-width: 796px) 100vw, 796px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e35cba2bbcee837c4e99922aafc7158b/d48f1/WebcamXP_04.png",
              "alt": "Windows Webcam XP",
              "title": "Windows Webcam XP",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Type in your camera´s IP address, http port and your camera login credentials. Use the Test function to verify your input, then click ok.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "792px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6f6a5868c0e38288752fba698d5b395c/9a86a/WebcamXP_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "79.13043478260869%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAAsTAAALEwEAmpwYAAADpElEQVQozzVR309bZQC90ecl+gfwok/G+LhEZ9TFaJyR6Ishm2aY8DAHkolRoy46mcIm/mCboWhhKwLjx6D8KowVsLS0QCmVHyuUlt7e0tuW/rj9vn733u9+3/1uLzDDoicnJ+ftnJzD3V/aGndtOpd51z8p31Z+JQr9ERDYRYFoyR859v8T+iNwdRetxlAgWvRvZ1e8a1zZPGJlU0YKAgBIEiwCWAQCLwhxIZ/NmeWyaRhlZuiE6ESDEAEANVU1DUOlB5w7ElgVhFl30HLb3js0OzjmGXZ4/xp4MDDqHplaHJvy9dtnZlz+4MO95WA8EROScSGwEVvwhx6GIlzLTOD3mbVfbBM1DT9d/rmvyTJxo2uuvX+xrc97q3vu1w7H5Rbbn91j48718dlIeCellOR4Ulrfiu3ySc4a3J7c2euzT33XfNNis3fdcw46vHZnYMQZGJz0dg85b3b09Q6MWq1/2Hp6frtlyWT2D83ygcFkmXBd3pVAaMMxOt7YdL3T1jNon5ienXctLDldXsf0zD37WLu1c2jI3tnW/MO3tZ99WpsR0xhrcgkBoHI7oe1IOGqzdTRdaei2Xr9jaR7t71hwDnsejPTeaWv+sbH2k7rW1huopBCiqyqGAMpIlhGCUOUopQeHR+7JvqG2z6fvXmu5cv7L+sqr39Q0f1/3xaUPG+o/ePWVkxcu1lJCDcY0rKmqyhjTdQpLmANQpuygvbWxqvL5rxvefa/yuepzL3116f2Pzr1effbMW2+eqqh4+uLHNbAIQRFIBUnKS+DxnQAoHIzchzvjo2PDp0+ffPnUC9VVL9bXvH2+6o13zrz2zLMVJ5468cSTXN2FaqYbuq5jFauqqjOmU3pcO7YyEfYNi6ncqj+47Fvyzf/t87gX5ufn5zzOaWfP3eHbXQMezyKCiGCiIEVBiqZqmoIhxBwfjfDh7UwqIyZEWIBSDuQyBR2zR4dHhSK1u8QJz956uEAVGSOsllQFKirCmoyPkzfcjjX3lLiXDod2hFgil8mqSMUK1jWay5Z8weRCIB4XCulkOiEk+Sgf2twS+L2kkBRTWS4R5xNCoiiBfDYvFYr5fAEcjwElSdKxFhdyoXC6TIiYyu3G4mIiCSUYjcQEnt/PStyjxzBN0zAMZjDDMHR2rIQQWVEMZpQNVjZNXWca1glhmkY1jVFqIIVyRCNAKsolRAklmPxHjWBFU2RFJzolVKcUQZwWQTJRzKQgv7sf2hREsfQvO9rznZ6ojL0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6f6a5868c0e38288752fba698d5b395c/e4706/WebcamXP_05.avif 230w", "/en/static/6f6a5868c0e38288752fba698d5b395c/d1af7/WebcamXP_05.avif 460w", "/en/static/6f6a5868c0e38288752fba698d5b395c/9983d/WebcamXP_05.avif 792w"],
              "sizes": "(max-width: 792px) 100vw, 792px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6f6a5868c0e38288752fba698d5b395c/a0b58/WebcamXP_05.webp 230w", "/en/static/6f6a5868c0e38288752fba698d5b395c/bc10c/WebcamXP_05.webp 460w", "/en/static/6f6a5868c0e38288752fba698d5b395c/22975/WebcamXP_05.webp 792w"],
              "sizes": "(max-width: 792px) 100vw, 792px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6f6a5868c0e38288752fba698d5b395c/81c8e/WebcamXP_05.png 230w", "/en/static/6f6a5868c0e38288752fba698d5b395c/08a84/WebcamXP_05.png 460w", "/en/static/6f6a5868c0e38288752fba698d5b395c/9a86a/WebcamXP_05.png 792w"],
              "sizes": "(max-width: 792px) 100vw, 792px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6f6a5868c0e38288752fba698d5b395c/9a86a/WebcamXP_05.png",
              "alt": "Windows Webcam XP",
              "title": "Windows Webcam XP",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Your camera is now ready to use.`}</p>
    <h3 {...{
      "id": "scheduled-video-recording",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#scheduled-video-recording",
        "aria-label": "scheduled video recording permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Scheduled Video Recording`}</h3>
    <p>{`WebcamXP comes with a powerful `}<strong parentName="p">{`Scheduler`}</strong>{` that allows you to set time windows for every action you want the software to perform. You can easily set it up to perform a scheduled video recording as well as to automate your motion detection function.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "797px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e9f056624d95ccfdc5259ee428d25670/43fbc/WebcamXP_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "77.82608695652173%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADAklEQVQ4y4VSaYskRRCt3+4v8IMIfvUHLKwiuArCrteOs2I7OFdPz3T3VNeVlXV05X1ExJI5PbjKigmPiIqMevleRhavfr713/26dj9dVPBmdYCzm4F+O+HshtPZ9Qk3H8PwtHfV0flqR9989doXSERikWG7O6jq0Kq6rGVVVqYqK902nZ7GSRptbAzBBv9v+BydCzZGcHfczsXX736g8/uje/dm5TePb+msfuunfprrplZ8GJTWGjOMydEYg0opNNbgMAy0e9iGptxHKya6KjkrPnnxGl6sOtturjVrNnZURyuEUNZasyxCK6WMMcY+RyGkcc5ZKZXRWplFanvdWLvpvbs6TLz49JdzvG9GXBYRjfEx+gBCiBhCQLEIUEqFYRhC3/c+1Yyx0XuPXcc8573rx2O47ADXA9G6Far44tX3xJsKq6bz99u935WVH4YRx2GkaZwSYl3VwHseETFdOWljqa7q2LadZ10XwemY6nedUMVfq4uwfyxjPUm42ddhU7MorM8/JiWsY1gdqjhPEz0TOh9QSBmPxwV2u33s+wHFssCGKVVYa8g5h7znvu+5s9YlW+h9QAAgYy0YY0AbAyHEfIhSGmIEOvWS1gaDd3TXSVXwcaFlEVi33O8eW9sPIrRs9LuyddPRRD4KaNkUWzYH45Ck9jjOCpSJWDU8ML4EHykrX7dSFd3ZZzQ2DyiUC13L3DQvQUgVOsY9HyaX7GltUSodk+EI2TgCIM3HBcZpDoCJEGmdFF58+zmW2w1GALDJlvcQQgDnHHjvgQgRYsQ04aQiX2SmxGw/9WS2TKhUcfnH78jq6qn5IwuR8O/8aShIJ4Z/9JwIf3z5JR0eNrkHAPB/Fv1HnhVnwtvLS0hjTxMNIWKMGXRCrqeJfriXDv4A+ZsQ6I5pVTzLzvcRAp2QckzPRAiJ1rlsLyZyANTGU4KxnpR2qLQnazzdMqMKvSw08yFqIchIiUYkCMy5lCjnGeXxCCNjUS0LWqWob0Y87BiWW5Yira8e43ZdwZ8Po3oPeO6/X/4usCwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e9f056624d95ccfdc5259ee428d25670/e4706/WebcamXP_07.avif 230w", "/en/static/e9f056624d95ccfdc5259ee428d25670/d1af7/WebcamXP_07.avif 460w", "/en/static/e9f056624d95ccfdc5259ee428d25670/48116/WebcamXP_07.avif 797w"],
              "sizes": "(max-width: 797px) 100vw, 797px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e9f056624d95ccfdc5259ee428d25670/a0b58/WebcamXP_07.webp 230w", "/en/static/e9f056624d95ccfdc5259ee428d25670/bc10c/WebcamXP_07.webp 460w", "/en/static/e9f056624d95ccfdc5259ee428d25670/9eee1/WebcamXP_07.webp 797w"],
              "sizes": "(max-width: 797px) 100vw, 797px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e9f056624d95ccfdc5259ee428d25670/81c8e/WebcamXP_07.png 230w", "/en/static/e9f056624d95ccfdc5259ee428d25670/08a84/WebcamXP_07.png 460w", "/en/static/e9f056624d95ccfdc5259ee428d25670/43fbc/WebcamXP_07.png 797w"],
              "sizes": "(max-width: 797px) 100vw, 797px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e9f056624d95ccfdc5259ee428d25670/43fbc/WebcamXP_07.png",
              "alt": "Windows Webcam XP",
              "title": "Windows Webcam XP",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The powerful scheduler allows you to automate functions like video recordings, motion detection or web broadcasts.`}</p>
    <h3 {...{
      "id": "web-broadcast",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#web-broadcast",
        "aria-label": "web broadcast permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Web Broadcast`}</h3>
    <p>{`The Web Broadcast function allows you to stream your camera´s live stream via HTTP and embed it into your website with ease. The earlier described scheduler enables you to automate your live broadcast according to your needs.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "790px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3827081ff91b5f3fd1544f3c421b0a55/2e237/WebcamXP_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "79.13043478260869%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACnklEQVQ4y6WSbW8cRQzH92vzZXiFELxEVQGVqoSg3rUFCk3SK7k8XJK7271kH2dnPA8ej41mL4nyihftSD95bM/a3r9c/DRfxGcHH/3LP1b46/s1/35c8ZtPjcw/NfLU7mllvmhktqhlfs9s0cj8ZCezk0pmL15LIUk4UeKhV6HrOtc3I243O9ysS9zcbEO265ttxt9cb5xSOkYkCgEphEiYmfxIIQkVb5Zv5a/TI9lutFkuVfi7meH55lxXZTXsbneqqipV13dKax0yAAattRPGGCy3ZbhaXdm6rhGMccXh/Dd+8epVvFrd2dNl7e905ZVWLgT0znsPAM5am6/5BER8xDk3NVFK5XchPyienxzx4WKRnPXc93XUI0QLlpiZLUDqug4BLLVth7vbW1RKUQiBQwjJWpu01gkAOKWU8jfFVy+P5evX/8hgifU4JOdcTkpKJIgoMUZhZnHOiQEjAHAfRyEiSURTXoQnv/j+3Yq+mZ3Fsh2pqVtfVrWzNlDTDsH5GNUIsevHGAJRJEnOYRy1xXEEbNshDMpgP2gctY25eRG9ZQqWvQ+sARO4mAwgaQhk7N5Od0B6iD/lIQYOEyJy0faam06x934a+0vONGH7789Svv9WPGYpJl0/6+RpYoxcnL17zscH37H1lGNT7nN4nPBiecqnxx84+CAPzb6o4IfDH/ntLz9wCCjCifc6TjwIyk+Q/xN6Knh9eZkuzi+S90FIhCkJE4tQkrxXTJR3Mk07tvdp8pkn+Inda/hYHb3gdsZoasHuTFDvGCmJc5bHUedCHO+L5r8xxgvYkGEDQQCCWIdSgDbStz0NbZNgdSDQrBnWfwrUKwbjRKuRx16xNZARB5b7dpT1dSPXl3e8vmrkfFmmk6NVLDct/wfloMd9jRlzLQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3827081ff91b5f3fd1544f3c421b0a55/e4706/WebcamXP_08.avif 230w", "/en/static/3827081ff91b5f3fd1544f3c421b0a55/d1af7/WebcamXP_08.avif 460w", "/en/static/3827081ff91b5f3fd1544f3c421b0a55/0db0f/WebcamXP_08.avif 790w"],
              "sizes": "(max-width: 790px) 100vw, 790px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3827081ff91b5f3fd1544f3c421b0a55/a0b58/WebcamXP_08.webp 230w", "/en/static/3827081ff91b5f3fd1544f3c421b0a55/bc10c/WebcamXP_08.webp 460w", "/en/static/3827081ff91b5f3fd1544f3c421b0a55/02aff/WebcamXP_08.webp 790w"],
              "sizes": "(max-width: 790px) 100vw, 790px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3827081ff91b5f3fd1544f3c421b0a55/81c8e/WebcamXP_08.png 230w", "/en/static/3827081ff91b5f3fd1544f3c421b0a55/08a84/WebcamXP_08.png 460w", "/en/static/3827081ff91b5f3fd1544f3c421b0a55/2e237/WebcamXP_08.png 790w"],
              "sizes": "(max-width: 790px) 100vw, 790px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3827081ff91b5f3fd1544f3c421b0a55/2e237/WebcamXP_08.png",
              "alt": "Windows Webcam XP",
              "title": "Windows Webcam XP",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Stream your camera´s live stream via HTTP.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "820px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9b1de27b8350873aa3eb040465d2ee14/9f82e/WebcamXP_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "89.56521739130436%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEH0lEQVQ4y3WR6U/bdRzHv/Ff8IGJ0agP5kzmiNlBYtgmysYZKIMotFmQggMcg3HTQg9KD6BcPVZ6sVLKrweU0rv9tYWeFEthSua4NG7uQS8kbA5WOVp+pjxwj3w/+Dx6v/J+5/0Bv/7+XGLxyeeXNYtP9IF1S3DDHNywrm7Cq1tw6m7CwadwcMO2sml/vG1a2X66vf1HNP4s8vfK+hYYtwUvNPMvtQmvdoo+rhsFKCK4TQYFXSAPDwrwIK8TZDaCrDZwsw3kEN7JbZFbfCUjJvSoSTprAMbAOnpYjeVqqx7qMKPqfBpUxJBnEiUZeHFG1/g1vDijlZfRLswmSvJ65Vlkmcri4MHrk/Y18YwWrG49axFrcRPGtnFDD2TtkpookJWqgAkyC00BE6csDJWdobTtvNpHECR5ihwfHyPIafz16729PRB/E//nYD95fHQYPziMH6QciRPk/3V6mjIkEsfHR0cguvuKoHQRVB6Swt2r8FBVPsq0jzq7NKxfHtAFqJqf6Lpl2lyApvYzZpfICq/YsSZxbwyafpkN/AZC4Vh5PQ3TMFCD4zX2SFoZctyQmsTSUnlGusDCEMIMoY0usNIFZgbfQBhWSTUeiT3GnfvT6I+CcCRW09p3DzfSThMTh+Q0zixTaGRJbLypBYHCJVB6BUovX+kRKFx8yDEs0qvNS86fd52Pw/4nf4FIJNbU3d9OYVOGJAMPlaxx7diUVaxakGq8Uzo/ZAhAxgCkX5JpFyc1bt6k2e5a3n35xru2cxjfB9FoDEeik+gjTLaYI4JEsrnJGYtCt6A2+7S2gH4+qHesaO0BjcU3Y3Q/UhicvpWz2ZIniQSIRqIEMoXWx2Rzx0SPJqYU0+o5o95kM8FO2OlzuJcc7iXYuWi2ufRmu1yl8fkDZz87TSaTKRjfhSeQiP0D/WwOSzwuFI+LIEjmsJm8Loff43DAFpvVbDLoNNNKsUg0P79wlnwGh0IhLBZNxtdOcLogPlnOJ8i43RwmQS3lwDMij1m+oJfN6yEtNMYaotTVYuXQZCr5TCAWCUmYDxzyXtd0v1ZCMcsYU9xmQktxH658kFBFJdVrFCzuCPFeHbq7o/JuZQGXO/gW3omGWd1VQ91YchNqgt0h5baVl1wuKrxQjLr4oK7oDvpGYcEXFWVfVd/Jab2PrqnI47L73sIvXjy/duXDb778IOfmJ52N+aWotKKCz8pKL393+9Joby2T9MP9u4W1WFR1RSGq6FbaxXMdnc0IgiQSiRQcDofwnbVZmVfS0s6lp3+ac+Oj4tzzleXp9dhsTMl1TMn178uzb3199fML5997/10AABqDQRDkJHGSgl/u7bmc8+SeHhwe30Pq4A40SMcoQg6Vz6aN9Hc1NVRhq39s7+j4tqy0tBSVm5/L5nD+q/0vjBCuBOgIYxsAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9b1de27b8350873aa3eb040465d2ee14/e4706/WebcamXP_09.avif 230w", "/en/static/9b1de27b8350873aa3eb040465d2ee14/d1af7/WebcamXP_09.avif 460w", "/en/static/9b1de27b8350873aa3eb040465d2ee14/bbc6f/WebcamXP_09.avif 820w"],
              "sizes": "(max-width: 820px) 100vw, 820px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9b1de27b8350873aa3eb040465d2ee14/a0b58/WebcamXP_09.webp 230w", "/en/static/9b1de27b8350873aa3eb040465d2ee14/bc10c/WebcamXP_09.webp 460w", "/en/static/9b1de27b8350873aa3eb040465d2ee14/b1f58/WebcamXP_09.webp 820w"],
              "sizes": "(max-width: 820px) 100vw, 820px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9b1de27b8350873aa3eb040465d2ee14/81c8e/WebcamXP_09.png 230w", "/en/static/9b1de27b8350873aa3eb040465d2ee14/08a84/WebcamXP_09.png 460w", "/en/static/9b1de27b8350873aa3eb040465d2ee14/9f82e/WebcamXP_09.png 820w"],
              "sizes": "(max-width: 820px) 100vw, 820px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9b1de27b8350873aa3eb040465d2ee14/9f82e/WebcamXP_09.png",
              "alt": "Windows Webcam XP",
              "title": "Windows Webcam XP",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Access your camera´s live stream with your default web browser.`}</p>
    <p>{`In the example above the video was tapped from the localhost address with a local web browser. You will need to set a port forwarding rule for the port 8080 (WebcamXP´s default web server port) to your workstation inside your internet router to make WebcamXP´s web server available from outside your local network.`}</p>
    <h3 {...{
      "id": "motion-detection",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#motion-detection",
        "aria-label": "motion detection permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Motion Detection`}</h3>
    <p>{`Switching to the Security tab gives you access to the software motion and audio detection feature. Activate the detection and adjust its sensitivity to your needs. You can also define a alarm trigger response - like a local video recording, a FTP upload or a notification email.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "796px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8ceb41fced56ebc744629360b341952e/d48f1/WebcamXP_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "78.69565217391303%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAAsTAAALEwEAmpwYAAADgElEQVQozz3RyW8bZQAF8Pk7kBD/ARISJ8qhNzigUgEHSisqUajEoSAQFaDQUiRQBBUpAak0pkCWEshShzQNTuI4devYjpfGdsb2LLZnbMdjz758s883C7KKkH7X9/Skh2xkamup6na+la4M9ut8qaUWSaVEqiVSLRDyAS79BxsrEHKJVIu4UMSFR8kc4rpe6AeCKB33jzsduo5iGEY26jiKNkmi02l3aapHdbo6ALZpaIoiCpIkKaZuDlgJyeG5GoFlqb0cmmkc1XECp7tUh+rQNMXzHM9z7RbZabfj67vrW/m1zXSxUMKaWK/TxTESeZh9cO+fbVmRXM9tt+liqYbWySOUyObLh9VGpdrYzxYrFXR6eurnW7fmFhYFQQzD0IeQZUUkiVaKOApkdcQMb99ZP3dp8rPJhWvT8W9n7k/9uvPD7O712ObSvcxvNye/ufLexKcfkDgBNKDIymAwQn5JF7YKB55lh2Gw+ndi4uvpm7/HZ1eSf93fX90qriQO5uN7iVROllTHhTowFFkBAJiGwTAcgh2hlfKhbhhRFK3ejU9cvToTi/2x+OdOcvdRJpNKPVi9G09sbUuiZFmWpmqqohqGYVvWOGyYhus4EMIoijbXFm9/f3lj4bvY1OWZG58vz91Ynv/pqy8uxWZ+5FhelmWBE3iOl0RZfTJ7KCisCBzHiaJoZXb643dOXr/29pk3nrtw7sRH75969/zLb75+8ssrn+jA8KBnWbZtW57n+RCOWAHpN5KDwyXdi6IoWl6aP/HCs6dfef7C2Rc/vPjqxfOnXjv90tPPPHXm7Fuj4WjEMASGNdA6TVEsw7TbNHInlZpLbLR4E8gSRXVLxcelQh6tHKK1anY/u7eXzmWzNNVF63ilgrapXrfHDBhOkrUW1UcyNTL9uCmpQBRFy3bCMITQD4JQA+B4MOA4zoMwDPyDamsl3dipsfm+n6HsDOXmsSGCPtyo7q5pqiYrqgd923Ed1/V9HwB9NBryvACh7zhuo94ol8s6AFEUBr4fRRHLC0iLJFtkWzdM1x3HXNe1Hce27PGZlqVqWhAEtu1QHRonCJZlAdA9DwZBOOIEBMJxjQehB73/QQgdxwEABEHg+34Yhp4HTcMxDFvXxxwbMqyIABUooiSLimVYpm6auvGErumaopmGZRkmz3I8KzF9uUfzg57UIoZNtNfE+v8C4e8dCmIMOigAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8ceb41fced56ebc744629360b341952e/e4706/WebcamXP_10.avif 230w", "/en/static/8ceb41fced56ebc744629360b341952e/d1af7/WebcamXP_10.avif 460w", "/en/static/8ceb41fced56ebc744629360b341952e/9b205/WebcamXP_10.avif 796w"],
              "sizes": "(max-width: 796px) 100vw, 796px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8ceb41fced56ebc744629360b341952e/a0b58/WebcamXP_10.webp 230w", "/en/static/8ceb41fced56ebc744629360b341952e/bc10c/WebcamXP_10.webp 460w", "/en/static/8ceb41fced56ebc744629360b341952e/7fcbc/WebcamXP_10.webp 796w"],
              "sizes": "(max-width: 796px) 100vw, 796px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8ceb41fced56ebc744629360b341952e/81c8e/WebcamXP_10.png 230w", "/en/static/8ceb41fced56ebc744629360b341952e/08a84/WebcamXP_10.png 460w", "/en/static/8ceb41fced56ebc744629360b341952e/d48f1/WebcamXP_10.png 796w"],
              "sizes": "(max-width: 796px) 100vw, 796px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8ceb41fced56ebc744629360b341952e/d48f1/WebcamXP_10.png",
              "alt": "Windows Webcam XP",
              "title": "Windows Webcam XP",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Enable the software motion and audio detection and define a trigger response.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      